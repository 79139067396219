.page-home {
  position: relative;
  min-height: 100vh;
  font-family: NovaSquare;
  background-color: #111;
  scroll-behavior: smooth;
  margin: auto;
        a:visited {
          text-decoration: none;
          color: #fff;
        }

        a:link {
          text-decoration: none;
        }



  &__container {

    @media only screen and (min-width: 1900px) {
      padding-left: 8%;
      padding-right: 8%;
    }

    @media only screen and (max-width: 1750px) {
      padding-left: 8%;
      padding-right: 8%;

    /* default */
    background: linear-gradient(to bottom, black 0%, transparent 3%),
      radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
      radial-gradient(closest-side at 50% 50%, #385075 0%, #38507500 100%),
      url("../../assets/images/cygbg.png"),
      radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
      radial-gradient(closest-side at 50% 50%, #385075 0%, #38507500 100%),
      radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
      url("../../assets/images/starsv2.png");
    background-size: 100%, 90% 1450px, 90% 1450px, 110%, 100% 1450px, 100% 1450px, 100% 1450px, 80%;
    background-position: center top, center -850px, center 10%, center 4%, 1000px 60%, -600px 80%, 1000px 100%, center 3%;
    background-repeat: no-repeat;
    }

    @media only screen and (max-width: 1200px) {
      padding-left: 8%;
      padding-right: 8%;
      max-width: 1200px;
      gap: 80px;

      /* laptops */
      background: linear-gradient(to bottom, black 0%, transparent 3%),
        radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
        radial-gradient(closest-side at 50% 50%, #385075 0%, #38507500 100%),
        radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
        radial-gradient(closest-side at 50% 50%, #385075 0%, #38507500 100%),
        radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
        url("../../assets/images/starsv2.png");
      background-size: 100%, 100% 2000px, 100% 2000px, 100% 2000px, 100% 2000px, 100% 2000px, 80%;
      background-position: center top, center -650px, center 10%, 600px 60%,
        -600px 80%, 600px 100%, center 3%;
      background-repeat: no-repeat;
    }

    @media only screen and (max-width: 768px) {
      max-width: 720px;
    }

    /* For mobile */
    @media only screen and (max-width: 576px) {
      max-width: 540px;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      gap: 50px;
      background: transparent;
    }

    margin: auto;
    padding-left: 2.75rem;
    padding-right: 2.75rem;
    padding-top: 36px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 150px;
    background-size: cover;

    /* default */
    background: linear-gradient(to bottom, black 0%, transparent 3%),
      radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
      radial-gradient(closest-side at 50% 50%, #385075 0%, #38507500 100%),
      url("../../assets/images/cygbg.png"),
      radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
      radial-gradient(closest-side at 50% 50%, #385075 0%, #38507500 100%),
      radial-gradient(closest-side at 50% 50%, #6c2f4d 0%, #6c2f4d00 100%),
      url("../../assets/images/starsv2.png");
    background-size: 100%, 90% 1450px, 90% 1450px, 100%, 100% 1450px, 100% 1450px, 100% 1450px, 80%;
    background-position: center top, center -850px, center 10%, center 0%, 1000px 60%, -600px 80%, 1000px 100%, center 3%;
    background-repeat: no-repeat;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 18px;

        img {
          height: 16px;
        }
      }

      &__navbar {
        display: flex;
        align-items: center;
        gap: 40px;
        color: #ffffff;

        a:visited {
          text-decoration: none;
          color: #fff;
        }

        a:link {
          text-decoration: none;
          color: #fff;
        }

        @media only screen and (max-width: 1200px) {
          display: none;
        }

        &__nav {
          cursor: pointer;
          transition: 0.3s;

          position: relative;

        a:visited {
          text-decoration: none;
          color: #fff;
        }

        a:link {
          text-decoration: none;
          color: #fff;
        }

          &:after {
            position: absolute;
            content: "";
            height: 2px;
            left: 0px;
            bottom: -6px;
            width: 0px;
            background-color: #4e7cff;
            transition: 0.3s;
          }
          &:hover::after {
            width: 100%;
          }
        }
      }

      &__button {
        &__connect-button {
          cursor: pointer;

          box-sizing: border-box;
          width: 197px;
          height: 42px;
          border: 4px solid #e84142;
          border-radius: 11px;
          font-family: novasquare;
          color: #fff;
          background-color: #111;

          transition: 0.3s;
          &:hover {
            box-shadow: 0 0 20px #e84142;
          }
          @media only screen and (max-width: 1200px) {
            display: none;
          }
        }
      }

      &__hamburger {
        display: none;
        z-index: 100;
        box-sizing: border-box;
        justify-content: center;

        &__bar {
          display: block;
          width: 25px;
          height: 3px;
          margin: 5px auto;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          background-color: #ffffff;
        }

        @media only screen and (max-width: 1200px) {
          display: block;
          cursor: pointer;
          &.active &__bar:nth-child(2) {
            opacity: 0;
          }
          &.active &__bar:nth-child(1) {
            -webkit-transform: translateY(8px) rotate(45deg);
            transform: translateY(8px) rotate(45deg);
          }
          &.active &__bar:nth-child(3) {
            -webkit-transform: translateY(-8px) rotate(-45deg);
            transform: translateY(-8px) rotate(-45deg);
          }
        }
      }

      &__sidebar {
        position: fixed;
        background: #111;
        top: 0;
        right: 0;
        color: #ffffff;
        height: 100%;
        transform-origin: right center;
        transform: scaleX(0);
        transition: all 0.5s;
        font-size: 24px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        width: 100%;

        a:visited {
          text-decoration: none;
          color: #fff;
        }

        a:link {
          text-decoration: none;
          color: #fff;
        }

        div {
          margin: 10px 0;
        }
        &__button {
          &__connect-button {
            box-sizing: border-box;
            width: 197px;
            height: 42px;
            border: 4px solid #e84142;
            border-radius: 11px;
            font-family: novasquare;
            color: #fff;
            background-color: #111;
            @media only screen and (max-width: 500px) {
              display: block;
            }
          }
        }
      }
    }

    &__1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      gap: 55px;
      justify-content: flex-end;
      @media only screen and (max-width: 768px) {
        gap: 30px;
      }
      padding: 0% 4%;

      &__title {
        display: flex;
        justify-content: center;

        margin-top: 60px;
        :nth-child(1) {
          width: 100%;
          display: none;
          // 576
          @media only screen and (max-width: 1200px) {
            display: block;
            width: 50%;
            margin-top: -50px;
          }
          @media only screen and (max-width: 900px) {
            display: block;
            width: 60%;
            margin-top: -50px;
          }
          @media only screen and (max-width: 768px) {
            display: block;
            width: 80%;
            margin-top: -50px;
          }
          @media only screen and (max-width: 576px) {
            display: block;
            width: 95%;
            margin-top: -50px;
          }
        }

        :nth-child(2) {
          width: 55%;
          @media only screen and (max-width: 1200px) {
            display: none;
          }
        }
      }

      &__texts {
        text-align: center;
        color: #d2c1ff;
        @media only screen and (min-width: 1400px) {
          max-width: 80%;
        }
        &__text-1 {
          font-size: 26px;
          @media only screen and (max-width: 768px) {
            color: #fff;
          }
        }

        &__text-2 {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          gap: 16px;
          img {
            width: 32px;
            height: 32px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      &__btns {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media only screen and (max-width: 992px) {
          display: flex;
          flex-direction: column;
        }

        &__btn {
          min-height: 56px;
          width: 240px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px #4e7cff solid;
          border-radius: 10px;
          color: #fff;

          @media only screen and (max-width: 1200px) {
            flex: 1;
          }
        }

        a:visited {
          text-decoration: none;
          color: #fff;
        }

        a:link {
          text-decoration: none;
        }

        :nth-child(1) {
          background-color: #4e7cff;
          color: #fff;
          cursor: pointer;
        }

        a {
          background-color: transparent;

        :nth-child(1) {
          background-color: transparent;
          color: #fff;
          transition: 0.3s;
          &:hover {
            box-shadow: 0 0 20px #4e7cff;
          }

        }
      }
      }

      &__links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        max-width: 300px;
        margin-left: 8px;

        @media only screen and (max-width: 800px) {
          gap: 40px;
        }

        &__link {
          img {
            max-height: 100%;
            max-width: 100%;
          }

          transition: 0.5s;
          &:hover {
            filter: drop-shadow(0 0 20px #fff);
          }
        }
      }
      &__infos {
        display: grid;
        align-items: center;
        line-height: 30px;
        text-align: center;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        color: #ffffff;
        margin: 50px 0px;

        @media only screen and (max-width: 1200px) {
          grid-template-columns: 1fr;
        }

        width: 100%;
        gap: 20px;

        &__info {
          border-radius: 10px;
          height: 100px;
          flex: 1;
          background-color: #4e7cff;
          display: flex;
          flex-direction: column;
          gap: 5px;
          justify-content: center;
        }
        :nth-child(5) {
          border-radius: 10px;
          background-color: #e84142;
        }
      }
    }

    &__2 {
      display: flex;
      flex-direction: column;
      gap: 40px;
      height: 100%;
      font-size: 24px;

      &__title {
        flex: 1;
        font-size: 80px;
        text-align: center;
        color: #ffffff;

        @media only screen and (max-width: 800px) {
          font-size: 50px;
        }
      }

      &__articles {
        color: #d2c1ff;

        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;

        @media only screen and (max-width: 1200px) {
          grid-template-columns: 1fr;
        }

        &__article {
          border-top: 2px #E89341 solid;
          border-bottom: 2px #E89341 solid;
          padding-top: 10px;
          padding-bottom: 10px;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 20px;

        a:visited {
          text-decoration: none;
          color: #fff;
        }

        a:link {
          text-decoration: none;
          color: #fff;
        }

          &__title {
          }

          &__date {
            font-size: 14px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            &__readmore {
            }
          }
        }
      }
    }

    &__3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      height: 100%;
      color: #ffffff;

      &__title {
        font-size: 80px;
        text-align: center;

        @media only screen and (max-width: 800px) {
          font-size: 50px;
        }
      }

      &__text {
        font-size: 18px;
        color: #d2c1ff;
        line-height: 30px;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        &__text {
          font-size: 18px;
          line-height: 30px;
          color: #d2c1ff;
          padding: 0 20px 0 0;
        }

        &__pool {
          font-size: 18px;
          flex: 1;
          background-color: #4e7cff;
          border-radius: 24px;
          &__header {
            display: flex;
            justify-content: space-between;
            padding: 16px 24px;
            &__left {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              &__logo {
                img {
                  height: 100px;
                }
              }
            }

            &__right {
              &__logo {
                img {
                  width: 50px;
                }
              }
            }
          }

          &__body {
            &__item {
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: space-between;
              text-align: right;
              gap: 20px;
              padding: 5px 24px;
              :nth-child(2) {
                width: 100px;
              }
            }
            &__item:nth-child(1) {
              background-color: #3e6be9;
            }
            &__item:nth-child(2) {
              background-color: #3360e1;
            }
            &__item:nth-child(3) {
              background-color: #2452d5;
            }
          }

          &__footer {
            padding: 16px 24px;
            font-size: 28px;
            text-shadow: 0 0 20px #fff;
          }
        }
      }
    }

    &__4 {
      color: #d2c1ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      &__title {
        color: #ffffff;
        font-size: 80px;
        text-align: center;

        @media only screen and (max-width: 800px) {
          font-size: 50px;
        }
      }

      &__articles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;

        @media only screen and (max-width: 1200px) {
          grid-template-columns: 1fr;
        }

        &__article {
          padding-top: 10px;
          padding-bottom: 10px;

          &__title {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 20px;
          }
          &__text {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }

      &__structure {
        display: flex;
        justify-content: center;
        img {
          width: 100%;
        }
      }
    }

    &__5 {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      &__title {
        font-size: 80px;
        text-align: center;

        @media only screen and (max-width: 800px) {
          font-size: 50px;
        }
      }

      &__texts {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr;
        font-size: 18px;
        color: #d2c1ff;
        line-height: 30px;

        @media only screen and (max-width: 1000px) {
          grid-template-columns: 1fr;
        }

        &__text {
        }
      }

      &__table {
        @media only screen and (min-width: 1200px) {
          max-width: 1000px;
        }

        background-color: #4e7cff;
        border-radius: 24px;
        width: 100%;

        &__body {
          display: grid;
          grid-template-columns: 2fr 1fr 3fr;
          gap: 2px;

          background-color: #4e7cff;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;

          @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr 1fr 3fr;
          }

          &__column {
            display: flex;
            flex-direction: column;
            gap: 1px;
            &__row {
              height: 5rem;
              display: flex;
              align-items: center;
              padding-left: 20px;
              padding-right: 20px;

              @media only screen and (max-width: 1200px) {
                height: 10rem;
                padding-left: 10px;
                padding-right: 10px;
              }
            }

            &__row:nth-child(1) {
              background-color: transparent;
              height: 6rem;
              text-align: center;
            }

            &__row:nth-child(2) {
              background-color: #3e6be9;
            }

            &__row:nth-child(3) {
              background-color: #3360e1;
            }

            &__row:nth-child(4) {
              background-color: #2d59d6;
            }

            &__row:nth-child(5) {
              background-color: #2452d5;
            }

            &__row:nth-child(6) {
              background-color: #1949d1;
            }
          }

          &__column:nth-child(2) {
            div {
              justify-content: center;
            }
          }
          &__column:nth-child(3) {
            div {
              justify-content: center;
            }
          }
        }

        &__footer {
          height: 20px;
        }
      }
    }
  }

  &__footer {
    background-color: black;
    margin-top: 100px;
    padding: 0 8%;
    &__container {
      margin: auto;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &__logo {
        img {
          height: 24px;
        }
      }

      &__text {
        color: #ffffff;

        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }

      &__links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        height: 100%;

        &__link {
          height: 100%;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 35px;
            width: 35px;
          }

          transition: 0.3s;
          &:hover {
            filter: drop-shadow(0 0 20px #fff);
          }
        }
      }
    }
  }
}

.scroll-panel {
  height: 100vh;
  overflow-y: scroll;
  position: absolute;
  scroll-behavior: smooth;
}
