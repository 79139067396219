.page-coming-soon {
  position: relative;
  font-family: RobotoMono;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  // background: black;
  // animation: colorChange 10s ease-in-out infinite;
  // animation-fill-mode: both;

  // @keyframes colorChange {
  //   0%,
  //   100% {
  //     opacity: 1;
  //   }
  //   50% {
  //     opacity: 0.8;
  //   }
  // }

  &__title {
    img {
      width: 100%;
    }
  }
  &__text-1 {
    text-align: center;
    color: greenyellow;
  }
  &__text-2 {
    text-align: center;
    text-transform: uppercase;
  }
  &__social-icons {
    display: flex;
    align-items: center;
    gap: 20px;
    &__1,
    &__2,
    &__3,
    &__4 {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      img {
        height: 50px;
        width: 50px;
      }

      transition: 0.3s;
      &:hover{
        box-shadow: 0px 0px 30px white;
      }
    }
  }
  &__launch-dapp {
    text-align: center;
    padding: 5px 20px;
    border-radius: 50px;
    border: 1px solid white;
    cursor: pointer;
    box-shadow: 0px 0px 0px white;
    background-color: transparent;
    color: white;
    text-transform: uppercase;
    transition: 0.3s;

    &:hover {
      box-shadow: 0px 0px 30px white;
      background-color: white;
      color: black;
    }
  }
}
